<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <template v-if="!isPrint">
                  <!--begin: Wizard Form-->
                  <form class="form">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h5
                        class="
                          font-weight-bolder
                          text-dark
                          font-size-h6 font-size-h3-lg
                        "
                      >
                        <span> SURAT KELAKUAN BAIK </span>
                      </h5>

                      <b-form-group
                        id="input-group-no"
                        label="Nomor Surat:"
                        label-for="input-no"
                      >
                        <b-form-input
                          id="input-no"
                          placeholder="Nomor Surat"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-date1"
                        label="Tanggal Surat:"
                        label-for="input-date1"
                      >
                        <b-form-datepicker
                          id="ex-disabled-readonly"
                        ></b-form-datepicker>
                      </b-form-group>
                      <b-form-group
                        id="input-group-no"
                        label="Nomor Pengantar:"
                        label-for="input-no"
                      >
                        <b-form-input
                          id="input-no"
                          placeholder="Nomor Pengantar"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-date2"
                        label="Tanggal Pengantar:"
                        label-for="input-date2"
                      >
                        <b-form-datepicker
                          id="ex-disabled-readonly"
                        ></b-form-datepicker>
                      </b-form-group>
                      <b-form-group
                        id="input-group-name"
                        label="Nama Penduduk:"
                        label-for="input-name"
                      >
                        <b-form-input
                          id="input-name"
                          placeholder="Nama Penduduk"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-description">
                        <label for="input-description">Untuk Keperluan:</label>
                        <b-form-textarea
                          id="input-description"
                          placeholder="Untuk keperluan"
                          rows="4"
                          max-rows="8"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button
                          type="button"
                          variant="primary"
                          @click="isPrint = true"
                          >Cetak</b-button
                        >
                        <!-- <a
                        href=""
                        download="/media/doc/example.pdf"
                        target="_blank"
                        >Cetak</a
                      > -->
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="default"
                          @click="$router.push('/letters/data')"
                        >
                          Batal
                        </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                  <!--end: Wizard Form-->
                </template>
                <template v-else>
                  <div>
                    <iframe
                      width="850"
                      height="700"
                      src="/media/doc/example.pdf"
                    >
                    </iframe>
                    <b-button
                      type="button"
                      class="ml-1 d-block mt-4"
                      variant="default"
                      @click="$router.push('/letters/data')"
                    >
                      Batal
                    </b-button>
                  </div>
                </template>
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      form: {},
      error: {},
      isPrint: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Menyurat", route: "/letters" },
      { title: "Buat Surat" },
    ]);
  },
};
</script>